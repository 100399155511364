
import Utils from "../../../utils";
import DefaultController from "../../defaultController";
import CredentialEntity from "../../credential/entity";

export default class SettingsCredentialsList extends DefaultController{


    async init() {
        // @ts-ignore
        this.id = "";
        this.entity = "credentials";
        await this.bindListeners();
        await this.getEntity();
        await Utils.hideLoader();
    }
    getEntityData(elem: any) {
        return CredentialEntity.getEntityData(elem)
    }

    async bindListeners() {
        (document.querySelector("#credentialsForm") as HTMLFormElement).addEventListener('submit', async (e) => {
            e.preventDefault();
            const form = (document.querySelector("#credentialsForm") as HTMLFormElement);
            const valid = form.checkValidity();
            if (valid) {

                const r = await Utils.entity.upsert(this.getEntityData(form), this.entity);
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('credential.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('credential.name')} ${Utils.translate('generic.messages.not_updated')}`, `${Utils.translate('generic.error')}`)
                }
            }
        });
    }
}